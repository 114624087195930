<style scoped></style>
<template>
  <div>
    <div class="text-h6 font-weight-bold mb-2 d-flex justify-space-between">
      <div>Loan Details</div>
      <div>
        <v-btn
          small
          outlined
          class="primary_2--text mr-4"
          @click="toggleAllAccordion()"
        >
          <v-tooltip left v-if="!are_all_collapsed">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary_2" v-bind="attrs" v-on="on">
                mdi-arrow-collapse-vertical
              </v-icon>
            </template>
            <span>Collapse all</span>
          </v-tooltip>
          <v-tooltip left v-else>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary_2" v-bind="attrs" v-on="on">
                mdi-arrow-expand-vertical
              </v-icon>
            </template>
            <span>Expand all</span>
          </v-tooltip>
        </v-btn>
      </div>
    </div>
    <v-card elevation="5">
      <v-card-text class="pa-0 font-weight-bold">
        <div v-for="item in accordionHeaders" :key="item.id">
          <div v-if="item.title == 'Other Documents'">
            <div
              class="primary_2 d-flex  white--text font-weight-bold px-2 mb-1"
            >
              <div
                @click="toggleAccordion(item)"
                style="width: 75%"
                class="d-flex align-center py-3"
              >
                <div class="text-body-1 font-weight-medium px-3">
                  {{ item.title }}
                </div>
              </div>
              <div style="width: 25%" class="d-flex align-center justify-end">
                <v-btn
                  small
                  depressed
                  color="secondary_2"
                  class="font-weight-bold white--text mr-3"
                  @click="requestOtherDocument()"
                  v-if="!is_other_doc_verified"
                  :disabled="is_other_doc_required"
                >
                  Request
                </v-btn>
                <v-icon
                  v-if="item.show"
                  color="white"
                  @click="toggleAccordion(item)"
                >
                  mdi-chevron-up
                </v-icon>
                <v-icon v-else color="white" @click="toggleAccordion(item)">
                  mdi-chevron-down
                </v-icon>
              </div>
            </div>
          </div>
          <div v-else>
            <div
              class="primary_2 d-flex  white--text font-weight-bold px-2 mb-1"
              @click="toggleAccordion(item)"
            >
              <div style="width: 75%" class="d-flex align-center py-3">
                <div class="text-body-1 font-weight-medium px-3">
                  {{ item.title }}
                </div>
              </div>
              <div style="width: 25%" class="d-flex align-center justify-end">
                <v-icon color="white" v-if="item.show">
                  mdi-chevron-up
                </v-icon>
                <v-icon color="white" v-else>
                  mdi-chevron-down
                </v-icon>
              </div>
            </div>
          </div>
          <div>
            <div
              v-if="item.title == $lang.LOAN_HISTORY && item.show"
              class="pb-3 px-3"
            >
              <LoanTable />
            </div>
            <div
              v-if="item.title == 'Other Documents' && item.show"
              class="pb-4 px-3"
            >
              <OtherDocuments />
            </div>
            <div
              v-if="item.title == $lang.VIDEO_VERIFICATION && item.show"
              class="pb-4 px-3"
            >
              <VideoVerificationDetails />
            </div>
            <div 
              v-if="item.title == $lang.EMI_DETAILS && item.show"
              class="py-4 px-3"
            >
              <EmiDetails />
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  components: {
    VideoVerificationDetails: () =>
      import("@/components/customer/VideoVerificationDetails"),
    LoanTable: () => import("@/components/customer/LoanTable"),
    EmiDetails: () => import("@/components/customer/EmiDetails"),
    OtherDocuments: () => import("@/components/new-customer/OtherDocuments"),
  },
  data() {
    return {
      accordionHeaders: [
        {
          id: 1,
          title: `${this.$lang.LOAN_HISTORY}`,
          show: true,
        },
        {
          id: 2,
          title: "Other Documents",
          show: true,
        },
        {
          id: 3,
          title: `${this.$lang.VIDEO_VERIFICATION}`,
          show: true,
        },
        {
          id: 4,
          title: `${this.$lang.EMI_DETAILS}`,
          show: true,
        },
      ],
      are_all_collapsed: false,

      active_loan_id: null,
      is_other_doc_required: false,
      is_other_doc_verified: false,
      is_active_loan_available: false,
    };
  },
  mounted() {
    this.getActiveLoanId();
  },
  methods: {
    toggleAccordion(item) {
      if (item.title == this.$lang.LOAN_HISTORY) {
        this.accordionHeaders[0].show = !this.accordionHeaders[0].show;
      } else if (item.title == "Other Documents") {
        this.accordionHeaders[1].show = !this.accordionHeaders[1].show;
      } else if (item.title == this.$lang.VIDEO_VERIFICATION) {
        this.accordionHeaders[2].show = !this.accordionHeaders[2].show;
      } else if (item.title == this.$lang.EMI_DETAILS) {
        this.accordionHeaders[3].show = !this.accordionHeaders[3].show;
      }
    },
    toggleAllAccordion() {
      if (this.are_all_collapsed) {
        this.accordionHeaders[0].show = true;
        this.accordionHeaders[1].show = true;
        this.accordionHeaders[2].show = true;
        this.accordionHeaders[3].show = true;
        this.are_all_collapsed = !this.are_all_collapsed;
      } else {
        this.accordionHeaders[0].show = false;
        this.accordionHeaders[1].show = false;
        this.accordionHeaders[2].show = false;
        this.accordionHeaders[3].show = false;
        this.are_all_collapsed = !this.are_all_collapsed;
      }
    },
    getActiveLoanId() {
      const self = this;
      let params = {
        customer_id: self.decrypt(this.$route.params.id),
      };
      console.log(params);
      const successHandler = (response) => {
        if (response.data.success) {
          if (response.data.loan_list[0]) {
            if (
              response.data.loan_list[0].loan_status == "Completed" ||
              response.data.loan_list[0].loan_status == "Rejected"
            ) {
              console.log();
            } else {
              self.active_loan_id = response.data.loan_list[0].loan_id;
              self.is_active_loan_available = true;
            }
          }

          self.is_other_doc_required = response.data.is_other_doc_required;
          self.is_other_doc_verified = response.data.is_document_verified;
        }
      };
      const finallyHandler = () => {};

      self.request_GET(
        self,
        self.$urls.LOAN_LIST,
        params,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
    requestOtherDocument() {
      if (this.is_active_loan_available) {
        const self = this;

        let form = new FormData();
        form.append("loan_id", self.active_loan_id);
        form.append("document_type", "other");

        const successHandler = (response) => {
          if (response.data.success) {
            this.showSnakeBar("green", "Other Document Requested Again");
            this.getActiveLoanId();
          }
        };
        const finallyHandler = () => {};
        self.request_POST(
          self,
          self.$urls.NEW_CUSTOMER_REQUEST_OTHER_DOCUMENT,
          form,
          successHandler,
          null,
          null,
          finallyHandler
        );
      }
      else{
        this.showSnakeBar("error", "No active loan available");
        }
    },
  },
};
</script>
